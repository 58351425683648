<template>
  <nowraan-nowrin req-url="users/nowraans" />
</template>
<script>
import NowraanNowrin from '@/components/NowraanNowrin.vue';
export default {
  components: {
    NowraanNowrin,
  },
};
</script>
